// .backgroundGradient {
//     background: linear-gradient(to bottom, #81c2ff 0%, #ffffff 100%);
//     // flex: 1;
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.logoImage {
    width: 500px;
    height: auto;
}

.logoDiv {
    z-index: 999;
}

.backgroundDiv {
    background: linear-gradient(to bottom, #81c2ff 0%, #ffffff 100%);
    height: 200vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
}

.testDiv {
    min-height: 100vh;
    background: linear-gradient(to bottom, #81c2ff 0%, #ffffff 100%);
    width: 100%;
}

.contentWrapper{
    max-width: 1600px;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

