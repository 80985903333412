.vmContentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    height: 500px;
    width: 100%;
    margin-bottom: 10%;
    margin-top: -5%;
    
    @media only screen and (max-width: 425px) {
        height: 600px;
    }
}

.vmProduct {
    width: 80%;
    z-index: 100;
    position: relative;

    @media only screen and (max-width: 1440px) {
        width: 100%;
    }

    @media only screen and (max-width: 1024px) {
        width: 120%;
    }
}

.vmTextBox {
    text-align: center;
    padding: 15px 0px;
    font-weight: 600;
}

.vmContentSectionContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 100;
    width: 55%;

    @media only screen and (max-width: 1024px) {
        width: 55%;
    }

    @media only screen and (max-width: 957px) {
        width: 55%;
    }

    @media only screen and (max-width: 576px) {
        width: 80%;
    }

    @media only screen and (max-width: 380px) {
        width: 80%;
    }
}