.footer-container {
    background: linear-gradient(to bottom, #ffffff 0%, #81c2ff 100%);
    min-height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;

    @media only screen and (max-width: 1024px) {
        margin-top: 10%;
    }

    @media only screen and (max-width: 768px) {
        margin-top: 30%;
    }

}

.footer-background {
    background-image: url('../../assets/images/background/forestBg.webp');
    min-height: 850px;
    height: 100vh;
    background-position: 0px 280px;
    position: absolute;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.footer-blur-background {
    backdrop-filter: blur(5px);
    background-color: rgba(28, 86, 5, 0.4);
    min-height: 250px;
    width: 100%;
    bottom: 0;
    position: absolute;
    z-index: 2;
    color: white;
}

.footer-details {
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;

    @media only screen and (min-width: 1440px) {
        padding-top: 15px;
    }

    @media only screen and (max-width: 1440px) {
        padding-top: 15px;
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px !important;
    }
}

.footer-icon,
.footer-contact {
    width: 50%;


}

.footer-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: -55px;
    margin-left: -50px;

    @media only screen and (max-width: 768px) {
        min-width: 100%;
        margin: 0 auto;
    }

    @media only screen and (max-width: 425px) {
        min-width: 100%;
        margin: 0 auto;

    }

    .footer-icon-logo {
        width: 318px;
        height: 100px;
        margin-left: 25%;

        @media only screen and (max-width: 768px) {
            width: 258px;
            height: 80px;
            margin: 0 auto;
        }

        @media only screen and (max-width: 425px) {
            width: 218px;
            height: 70px;
            margin: 0 auto;
        }


    }

    .footer-icon-social {
        display: flex;
        justify-content: flex-start;
        margin-left: 27%;
        margin-top: 0px;

        @media only screen and (max-width: 768px) {
            display: flex;
            justify-content: flex-start;
            margin: 0 auto;
            padding: 45px 0px 0px 0px;
        }

        @media only screen and (max-width: 425px) {
            display: flex;
            justify-content: flex-start;
            margin: 0 auto;
        }

        img {
            margin-left: 25px;
            width: 55px;
            height: 43px;
            cursor: pointer;

            @media only screen and (max-width: 768px) {
                margin-left: 30px;
                margin-top: 5px;
                // width: 48px;
                // height: 35px;
            }

            @media only screen and (max-width: 425px) {
                margin-left: 20px;
                // width: 48px;
                // height: 35px;
            }
        }
    }
}

.footer-contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: -40px;
    padding-top: 50px;
    margin-right: -50px;

    @media only screen and (max-width: 768px) {
        padding-top: 0px;

    }

    @media only screen and (max-width: 425px) {
        padding-top: 0px;
        margin-right: 0px;

    }

    @media only screen and (max-width: 320px) {
        padding-top: 0px;
        margin-right: -5px;

    }

    .footer-contact-details {
        margin-right: 24px;
        margin-top: 15px;

        @media only screen and (max-width: 768px) {
            margin-bottom: 20px;
        }

        @media only screen and (max-width: 425px) {
            margin-top: 20px;
            margin-right: 10px;
        }

    }

    img {
        width: 93px;

        @media only screen and (max-width: 768px) {
            width: 80px;
            padding-top: 15px;
        }

        @media only screen and (max-width: 425px) {
            width: 75px;
            padding-top: 20px;
        }

        @media only screen and (max-width: 320px) {
            width: 65px;
            margin-right: 20px;
        }
    }

    div {
        text-align: right;
        font-family: sans-serif;
        font-size: 16px;
        padding: 2px 0;

        @media only screen and (max-width: 768px) {
            min-width: 250px;
            font-size: 14px;
        }

        @media only screen and (max-width: 425px) {
            font-size: 12px;
        }

    }

    .footer-phone {
        font-size: 16px;

        @media only screen and (max-width: 768px) {
            font-size: 14px;
        }

        @media only screen and (max-width: 425px) {
            font-size: 12px;
        }
    }
}

.footer-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;

    @media only screen and (min-width: 1024px) {
        max-width: 80%;
        margin: 0 auto;
    }

    @media only screen and (max-width: 768px) {
        max-width: 100%;
        margin: 0 auto;
    }

    p {
        font-size: 16px;
        padding: 4px 32px;
        cursor: pointer;

        @media only screen and (max-width: 768px) {
            font-size: 12px;
        }

        @media only screen and (max-width: 425px) {
            font-size: 12px;
            padding: 4px 16px;
        }
    }
}

.footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }

    @media only screen and (max-width: 425px) {
        font-size: 11px;
    }

    @media only screen and (max-width: 375px) {
        font-size: 11px;
    }

    @media only screen and (max-width: 320px) {
        font-size: 11px;
    }

}

.footer-product {
    position: absolute;
    width: 420px;
    z-index: 3;
    flex: 1;
    left: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (max-width: 576px) {
        margin: 0 auto;
        width: 300px;
        margin-top: 135px;
    }

    @media only screen and (max-width: 375px) {
        margin: 0 auto;
        width: 280px;
        margin-top: 115px;
    }

    @media only screen and (max-width: 320px) {
        margin: 0 auto;
        width: 270px;
        margin-top: 160px;
    }
}