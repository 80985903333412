.logoImage {
    width: 100%;
    height: auto;
}

.logoImageSmall {
    width: 250px;
    height: auto;
}

.logoDiv {
    z-index: 90;
    display: inline-flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 50px;
    position: absolute;
}

.headerMenuBar {
    height: 50px;
    width: 100%;
    top: 0;
    overflow: hidden;
    position: fixed;
    z-index: 99999 !important;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    p span {
        cursor: pointer;
        padding: 0px 20px;
        font-size: 13px;
    }
}


.headerLogo {
    z-index: 99999 !important;
}

.cloudBg1Container {
    position: relative;
    overflow-x: hidden;
    background-image: url('../../assets/images/background/headerCloud1.webp');
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    // min-height: 10vw;
    // height: 1200px;
}

.cloudBg2Container {
    position: absolute;
    z-index: 10;
    background-color: transparent;
    overflow-x: hidden;
    background-image: url('../../assets/images/background/cloudLeft.webp');
    width: 70%;
    height: 100%;
    background-position: left;
    background-size: contain;
    // left: -25%;
    // bottom: -45%;
    background-repeat: no-repeat;
    -webkit-animation: slideLeft 1.5s forwards;
    animation: slideLeft 1.5s forwards;
}

.parallaxContainer {
    display: flex;
}

.cloudStyle {
    height: max-content;
}

@-webkit-keyframes slideLeft {
    0% {
        left: -100%;
    }

    100% {
        left: -30%;
    }
}

@keyframes slideLeft {
    0% {
        left: -100%;
    }

    100% {
        left: -30%;
    }
}


.cloudBg3Container {
    position: absolute;
    z-index: 10;
    background-color: transparent;
    overflow-x: hidden;
    background-image: url('../../assets/images/background/cloudRight.webp');
    width: 50%;
    height: 100%;
    background-position: right;
    background-size: contain;
    right: 0%;
    // bottom: -5%;
    background-repeat: no-repeat;
    -webkit-animation: slideRight 1.5s forwards;
    animation: slideRight 1.5s forwards;
}

@-webkit-keyframes slideRight {
    0% {
        right: -70%;
    }

    100% {
        right: 0%;
    }
}

@keyframes slideRight {
    0% {
        right: -70%;
    }

    100% {
        right: 0%;
    }
}

.buttonContainer {
    position: fixed;
    top: 8%;
    z-index: 9999;
    right: 5%;
}

.mbButtonContainer {
    position: fixed;
    top: 5%;
    z-index: 99999 !important;
    right: 5%;
}

.buttonStyle {
    border: none;
    border-radius: 100%;
    display: inline-flex;
    width: 40px;
    padding: 10px;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.backdropMenu {
    height: 100vh;
    width: 100%;
    position: fixed;
    backdrop-filter: blur(50px);
    z-index: 99999;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    // color:;
}

.menuList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    top: 50%;
    // position: relative;
}

.textButton {
    cursor: pointer;
}

.section1Wrapper {
    height: 800px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    background: linear-gradient(to bottom, #81c2ff 0%, #ffffff 100%);
}