.parallaxContainer {
    height: 150vh;
    background: linear-gradient(to bottom, #81c2ff 0%, #ffffff 100%);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    width: 100vw;
    position: relative;
    min-height: 50vh;
}

.parallaxContainerP2 {
    height: 150vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    width: 100vw;
    position: relative;
    min-height: 50vh;

    @media only screen and (max-width: 768px) {
        height: 130vh;
    }

    
    @media only screen and (max-width: 576px) {
        height: 110vh;
        margin-bottom: 25vh;
    }
    
    @media only screen and (max-width: 360px) {
        height: 110vh;
        margin-bottom: 15vh;
    }
}

.parallaxContainerP3 {
    height: 150vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    width: 100vw;
    position: relative;
    min-height: 50vh;
    margin-top: -20vh;
        
    @media only screen and (max-width: 425px) {
        height: 110vh;
        margin-bottom: 25vh;
    }
        
    // @media only screen and (max-width: 360px) {
    //     height: 100vh;
    //     margin-bottom: 25vh;
    // }
}

.parallaxContainerP4 {
    height: 200vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    width: 100vw;
    position: relative;
    min-height: 50vh;
    margin-top: -70vh;
    z-index: 15;

    @media only screen and (max-width: 425px) {
        height: 180vh;
    }
}

.parallaxContainerP5 {
    height: 160vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    width: 100vw;
    position: relative;
    min-height: 50vh;
    margin-top: -95vh;
}

.parallaxContainerP6 {
    height: 130vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    width: 100vw;
    position: relative;
    min-height: 50vh;
    z-index: 30;
}

.parallaxContainerP7 {
    height: 120vh;
    background: linear-gradient(to bottom, #ffffff 0%, #81c2ff 100%);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    width: 100vw;
    position: relative;
    min-height: 50vh;
    bottom: 0%;
    margin-top: 80vh;

    @media only screen and (max-width: 1024px) {
        height: 120vh;
    }

    @media only screen and (max-width: 768px) {
        height: 130vh;
        margin-top: 50vh;
    }

    @media only screen and (max-width: 576px) {
        height: 120vh;
        margin-top: 30vh;
    }
    
    @media only screen and (max-width: 425px) {
        height: 120vh;
        margin-top: 50vh;
    }
}

.cloud1Div {
    width: 100%;
    height: auto;
}

.cloudLeftDiv {
    background-size: contain !important;
    background-repeat: no-repeat;
}

.cloudRightDiv {
    background-size: contain !important;
    background-repeat: no-repeat;
}

.logoDiv {
    background-size: contain !important;
    background-repeat: no-repeat;
}

.prdLeafClipA {
    background-size: contain !important;
    background-repeat: no-repeat;
}

.prdLeafClipB {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 80%;
}

.prdImg1Div {
    background-size: contain !important;
    background-repeat: no-repeat;
    z-index: 50;
}

.splashImgDiv {
    background-size: contain !important;
    background-repeat: no-repeat;
}

.ingBgDiv {
    background-repeat: no-repeat;
}

.forestBgDiv {
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    inset: 200px 0px -200px !important;
}

.footerForestDiv {
    background-size: cover !important;
    background-repeat: no-repeat;
    width: 100%;
    bottom: 0px !important;
    inset: -60px 0px !important;
    background-position: 0px 175px !important;
}

.footerBlurDiv {
    width: 100%;
    display: flex;
    align-items: flex-end;
    z-index: 49990 !important;
}

.cloudJoinDiv {
    background-repeat: no-repeat;
    width: 100%;
}

.prdImg4Div {
    background-size: contain !important;
    background-repeat: no-repeat;
}

.prdImg5Div {
    background-size: contain !important;
    background-repeat: no-repeat;
    // inset: -380px 0px 100px !important;
    z-index: 50000 !important;
}

.bannerLogo {
    width: 100%;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-top: -30%;
    z-index: 999;
    position: relative;
}

.logoSmallContainer {
    z-index: 999;
}

.section2 {
    position: absolute;
}

.textBox1 {
    display: flex;
    justify-content: center;
    min-height: 50px;
    width: 100%;
}

.prdContentBox1 {
    height: 400px;
    width: 50%;
    min-width: 700px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    backdrop-filter: blur(25px);
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;

    @media only screen and (max-width: 768px) {
        width: 70%;
        min-width: unset;
        height: 300px;
    }
}

.prdContentText1 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5% 10%;
}

.leafDiv {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100%;
}

.blueberryDiv {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100%;
}

.orangeDiv {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100%;
}

.nucleotideDiv {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100%;
}

.textBox2 {
    display: flex;
    justify-content: center;
    min-height: 50px;
    width: 100%;
}

.prdContentBox2 {
    height: auto;
    width: 70%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
        width: 80%;
    }
}

.prdContentText2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5% 10%;
}

.product-logo-official {
    justify-content: center;
    margin-top: 0px;
    align-items: center;
    display: flex;
    background-size: cover;
   
    img {
        margin-left: 25px;
        max-width: 120px;
        max-height: 110px;
        
        @media only screen and (max-width: 1080px) {
            margin-left: 20px;
            max-width: 110px;
            max-height: 100px;
        }

        @media only screen and (max-width: 768px) {
            margin-left: 15px;
            max-width: 100px;
            max-height: 90px;
        }

        @media only screen and (max-width: 576px) {
            margin-left: 10px;
            max-width: 85px;
            max-height: 75px;
        }

        @media only screen and (max-width: 425px) {
            margin-left: 8px;
            max-width: 65px;
            max-height: 55px;
        }

        @media only screen and (max-width: 340px) {
            margin-left: 5px;
            max-width: 55px;
            max-height: 45px;
        }


       
    }
}

.textBox3 {
    display: flex;
    justify-content: center;
    min-height: 50px;
    width: 100%;
}

.prdContentBox3 {
    width: 50%;
    display: flex;
    min-height: 300px;
    min-width: 700px;
    align-items: center;
    align-content: center;
    justify-content: center;
    backdrop-filter: blur(25px);
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;

    @media only screen and (max-width: 768px) {
        min-width: unset;
        width: 60%;
    }

    @media only screen and (max-width: 576px) {
        min-width: unset;
        width: 70%;
    }

    @media only screen and (max-width: 360px) {
        min-width: unset;
        width: 80%;
    }
}

.prdContentText3 {
    display: flex;
    flex-direction: column;
    padding: 3% 3%;
    color: white;
    display: flex;
    // min-height: 300px;
    align-items: center;
    align-content: center;
    justify-content: center;
    backdrop-filter: blur(25px);
    border-radius: 25px;

    @media only screen and (max-width: 768px) {
        padding: 5% 5%;
    }
}


.prdContentText3Wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
}

.prdImg2Div {
    width: 100%;

    @media only screen and (max-width: 768px) {
        width: 75%;
    }
}

.carouselWrapper {
    width: 100%;
}

.leaf2Div {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100%;
    inset: -84px -193px 0px !important;
}

.ladybirdDiv {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100%;
}

.productOpenDiv {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100%;
}

.nucleoWrapper {
    z-index: 5;
    width: 450px;
    position: absolute;
    top: -40%;
    left: -75%;

    @media only screen and (max-width: 1024px) {
        left: -55%;
    }

    @media only screen and (max-width: 768px) {
        top: -20%;
        left: -60%;
    }
}

.nucleoClip {
    width: 160%;
    z-index: 5;

    @media only screen and (max-width: 1024px) {
        width: 140%;
    }

    @media only screen and (max-width: 768px) {
        width: 150%;
    }
}

.atpWrapper {
    z-index: 5;
    width: 450px;
    position: absolute;
    top: -30%;
    left: -75%;

    @media only screen and (max-width: 1024px) {
        left: -55%;
    }

    @media only screen and (max-width: 768px) {
        top: -20%;
        left: -70%;
    }
}

.atpClip {
    width: 180%;
    z-index: 5;

    @media only screen and (max-width: 1024px) {
        width: 140%;
    }

    @media only screen and (max-width: 768px) {
        width: 160%;
    }
}

.ascorbicWrapper {
    z-index: 5;
    width: 450px;
    position: absolute;
    top: -65%;
    left: -75%;

    @media only screen and (max-width: 1024px) {
        top: -45%;
        left: -55%;
    }

    @media only screen and (max-width: 768px) {
        top: -50%;
        left: -80%;
    }
}

.ascorbicClip {
    width: 180%;
    z-index: 5;

    @media only screen and (max-width: 1024px) {
        width: 150%;
    }

    @media only screen and (max-width: 768px) {
        width: 180%;
    }
}

.orangeWrapper {
    z-index: 5;
    width: 450px;
    position: absolute;
    top: -30%;
    left: -45%;

    @media only screen and (max-width: 1024px) {
        top: -20%;
        left: -40%;
    }
}

.orangeClip {
    width: 100%;
    z-index: 5;

    @media only screen and (max-width: 1024px) {
        width: 90%;
    }
}

.blueberryWrapper {
    z-index: 5;
    width: 450px;
    position: absolute;
    top: -60%;
    left: -75%;

    @media only screen and (max-width: 1024px) {
        top: -50%;
        left: -55%;
    }

    @media only screen and (max-width: 768px) {
        top: -25%;
        left: -55%;
    }
}

.blueberryClip {
    width: 150%;
    z-index: 5;

    @media only screen and (max-width: 1024px) {
        width: 130%;
    }
}

.pomegWrapper {
    z-index: 5;
    width: 450px;
    position: absolute;
    top: -30%;
    left: -55%;

    @media only screen and (max-width: 1024px) {
        top: -25%;
        left: -40%;
    }
}

.pomegClip {
    width: 115%;
    z-index: 5;

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
}

.acaiberryWrapper {
    z-index: 5;
    width: 450px;
    position: absolute;
    top: -30%;
    left: -40%;

    @media only screen and (max-width: 1024px) {
        top: -30%;
        left: -30%;
    }

    @media only screen and (max-width: 768px) {
        top: -20%;
        left: -30%;
    }
}

.acaiberryClip {
    width: 90%;
    z-index: 5;

    @media only screen and (max-width: 1024px) {
        width: 80%;
    }
}

.ingTextWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 300px;
    backdrop-filter: blur(20px);
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    width: 450px;
    margin-left: 30%;
    background: rgb(217 160 160 / 20%);
    z-index: 999;
    position: relative;

    @media only screen and (max-width: 768px) {
        margin-left: 0%;
        width: 100%;
        min-height: 350px;
    }

    @media only screen and (max-width: 360px) {
        margin-left: 5%;
        width: 90%;
        min-height: 350px;
    }
}

.ingTextWrapper2 {
    padding: 5px;
}

.ingCarousel>.slick-prev {
    left: 50px;
    z-index: 80;

    @media only screen and (max-width: 425px) {
        left: 10px;
    }
}

.ingCarousel>.slick-next {
    right: 50px;
    z-index: 80;

    @media only screen and (max-width: 425px) {
        right: 10px;
    }
}

.ingCarousel>.slick-list>.slick-track>.slick-slide>div {
    transform: scale(0);
    transition: transform .3s cubic-bezier(.4, 0, .2, 1);
}

.ingCarousel>.slick-list>.slick-track>.slick-center>div {
    transform: scale(1);
}

.ingCarousel>.slick-list>.slick-track>.slick-slide {
    height: 700px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.ingCarouselDiv {
    z-index: 9999 !important;
}

.footerBox {
    min-height: 300px;
    backdrop-filter: blur(10px);
    background-color: rgba(28, 86, 5, 0.4);
    width: 100%;
    color: white;
}