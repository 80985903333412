.section2Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    height: 1250px;
    margin-top: 10%;
    width: 100%;

    @media only screen and (max-width: 1024px) {
        height: 900px;
    }

    @media only screen and (max-width: 957px) {
        height: 900px;
    }

    @media only screen and (max-width: 576px) {
        height: 600px;
        margin-bottom: 50px;
    }

    @media only screen and (max-width: 380px) {
        height: 600px;
        margin-bottom: 50px;
    }
}

.section3Wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    height: 750px;
    align-content: center;
    margin-top: -8%;
    width: 100%;

    @media only screen and (max-width: 1024px) {
        height: 700px;
    }

    @media only screen and (max-width: 957px) {
        height: 800px;
    }

    @media only screen and (max-width: 576px) {
        height: 600px;
    }

    @media only screen and (max-width: 380px) {
        height: 600px;
    }
}

.section4Wrapper {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100px;
    justify-content: flex-start;
    margin-top: -15%;
    overflow: hidden;
}

.productSection {
    position: absolute;
    width: 100%;
}

.productSection2 {
    position: absolute;
    width: 100%;
    z-index: 999;
}

.productSection3 {
    position: relative;
    width: 100%;
    min-width: 100vw;
    z-index: 450;
    margin-bottom: -20%;
    margin-top: -12%;

    @media only screen and (max-width: 425px) {
        margin-bottom: -30%;
    }

    @media only screen and (max-width: 375px) {
        margin-bottom: -35%;
    }

    @media only screen and (max-width: 320px) {
        margin-bottom: -35%;
    }
}

.splashImgBg {
    width: 120%;
    z-index: 0;
    right: -10%;
    position: relative;

    @media only screen and (max-width: 1024px) {
        width: 120%;
        right: -10%;
    }

    @media only screen and (max-width: 957px) {
        width: 120%;
        right: -10%;
    }

    @media only screen and (max-width: 576px) {
        width: 200%;
        right: 10%;
    }

    @media only screen and (max-width: 380px) {
        width: 200%;
        right: 10%;
    }
}

.forestImgBg {
    width: 100%;
    z-index: 15;
    position: relative;

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }

    @media only screen and (max-width: 957px) {
        width: 100%;
    }

    @media only screen and (max-width: 576px) {
        width: 200%;
    }

    @media only screen and (max-width: 380px) {
        width: 250%;
    }
}

.logoAbsContainer {
    z-index: 999;
    top: 0;
    position: absolute;
    left: 10%;
}

.logoFixedContainer {
    z-index: 9999;
    top: 50px;
    position: fixed;
    left: 50px;

    @media only screen and (max-width: 768px) {
        top: 40px;
        left: 40px;
    }

    @media only screen and (max-width: 580px) {
        top: 35px;
        left: 35px;
    }

    @media only screen and (max-width: 430px) {
        top: 30px;
        left: 30px;
    }

    @media only screen and (max-width: 380px) {
        top: 25px;
        left: 25px;
    }
}

.logoShort {
    width: 60%;

    @media only screen and (max-width: 1024px) {
        width: 60%;
    }

    @media only screen and (max-width: 957px) {
        width: 60%;
    }

    @media only screen and (max-width: 768px) {
        width: 45%;
    }

    @media only screen and (max-width: 580px) {
        width: 35%;
    }

    @media only screen and (max-width: 380px) {
        width: 35%;
    }
}

.contentBox {
    backdrop-filter: blur(25px);
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    min-height: 380px;
    width: 50%;
    position: absolute;
    top: 35%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;


    @media only screen and (max-width: 1024px) {
        margin: 0% 5%;
        width: 70%;
    }

    @media only screen and (max-width: 957px) {
        margin: 0% 5%;
        width: 80%;
    }

    @media only screen and (max-width: 576px) {
        margin: 0% 5%;
        width: 80%;
    }

    @media only screen and (max-width: 380px) {
        margin: 0% 5%;
        width: 80%;
    }
}

.contentBox2 {
    width: 50%;
    position: absolute;
    top: 10%;
    border-radius: 10px;

    @media only screen and (max-width: 1024px) {
        top: 15%;
        width: 70%;
    }

    @media only screen and (max-width: 768px) {
        top: 15%;
        width: 80%;
    }

    @media only screen and (max-width: 425px) {
        top: 15%;
        width: 80%;
    }

    @media only screen and (max-width: 375px) {
        top: 15%;
        width: 80%;
    }
}

.contentBox3 {
    backdrop-filter: blur(20px);
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    min-height: 300px;
    width: 50%;
    position: absolute;
    color: white;

    @media only screen and (max-width: 1024px) {
        width: 70%;
    }

    @media only screen and (max-width: 957px) {
        width: 80%;
    }

    @media only screen and (max-width: 576px) {
        width: 80%;
    }

    @media only screen and (max-width: 380px) {
        width: 80%;
    }
}

.contentSection {
    height: 100%;
    width: 100%;
    z-index: 999;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    bottom: 1%;
    position: absolute;
    background-color: transparent;
}

.contentSection2 {
    height: 100%;
    width: 100%;
    z-index: 999;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    bottom: 1%;
    position: absolute;
    background-color: transparent;
}

.contentSection3 {
    height: 100%;
    width: 100%;
    z-index: 999;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    // bottom: -3%;
    position: absolute;
    background-color: transparent;
}

.prdContent {
    display: flex;
    flex-direction: column;
    padding: 10% 20%;

    @media only screen and (max-width: 1024px) {
        padding: 5% 5%;
    }
}

.prdContent2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10% 10%;

    @media only screen and (max-width: 1024px) {
        padding: 15% 5%;
    }

    @media only screen and (max-width: 768px) {
        padding: 25% 5%;
    }

    @media only screen and (max-width: 425px) {
        padding: 35% 5%;
    }

    @media only screen and (max-width: 375px) {
        padding: 15% 5%;
    }
}

.prdContent3 {
    display: flex;
    flex-direction: column;
    padding: 3% 3%;
}

.prdLeafClip {
    width: 60%;

    @media only screen and (max-width: 1024px) {
        width: 60%;
    }

    @media only screen and (max-width: 957px) {
        width: 55%;
    }

    @media only screen and (max-width: 576px) {
        width: 35%;
    }

    @media only screen and (max-width: 380px) {
        width: 35%;
    }
}

.prdLeafClip2 {
    width: 80%;

    @media only screen and (max-width: 1024px) {
        width: 80%;
    }

    @media only screen and (max-width: 957px) {
        width: 60%;
    }

    @media only screen and (max-width: 576px) {
        width: 40%;
    }

    @media only screen and (max-width: 380px) {
        width: 25%;
    }
}

.prdBlueberryClip {
    width: 70%;

    @media only screen and (max-width: 1024px) {
        width: 70%;
    }

    @media only screen and (max-width: 957px) {
        width: 60%;
    }

    @media only screen and (max-width: 576px) {
        width: 50%;
    }

    @media only screen and (max-width: 380px) {
        width: 50%;
    }
}

.prdOrangeClip {
    width: 70%;

    @media only screen and (max-width: 1024px) {
        width: 70%;
    }

    @media only screen and (max-width: 957px) {
        width: 60%;
    }

    @media only screen and (max-width: 576px) {
        width: 50%;
    }

    @media only screen and (max-width: 380px) {
        width: 50%;
    }
}

.prdNucleoClip {
    width: 70%;

    @media only screen and (max-width: 576px) {
        width: 70%;
    }

    @media only screen and (max-width: 380px) {
        width: 70%;
    }
}

.prdLeafClipContainer {
    position: absolute;
    left: -5%;
    top: -10%;
}

.prdLeafClipContainer2 {
    position: absolute;
    right: -40%;
    top: 60%;

    @media only screen and (max-width: 1024px) {
        right: -40%;
        top: 60%;
    }

    @media only screen and (max-width: 768px) {
        right: -35%;
        top: 50%;
    }

    @media only screen and (max-width: 576px) {
        right: -55%;
        top: 60%;
    }

    @media only screen and (max-width: 380px) {
        right: -80%;
        top: 60%;
    }
}

.prdLeafClipContainer3 {
    position: absolute;
    left: -25%;
    top: 13%;

    @media only screen and (max-width: 1024px) {
        left: -25%;
        top: 13%;
    }

    @media only screen and (max-width: 957px) {
        left: -20%;
        top: 13%;
    }

    @media only screen and (max-width: 576px) {
        left: -25%;
        top: 13%;
    }

    @media only screen and (max-width: 380px) {
        left: -15%;
        top: 13%;
    }
}

.prdLeafClipContainer4 {
    position: absolute;
    left: -5%;
    bottom: -3%;
    z-index: 5;
}

.prdBlueberryClipContainer {
    position: absolute;
    left: -15%;
    bottom: -10%;

    @media only screen and (max-width: 1024px) {
        left: -15%;
        bottom: -10%;
    }

    @media only screen and (max-width: 957px) {
        left: 0%;
        bottom: -10%;
    }

    @media only screen and (max-width: 576px) {
        left: 0%;
        bottom: -10%;
    }

    @media only screen and (max-width: 380px) {
        left: 0%;
        bottom: -10%;
    }
}

.prdOrangeClipContainer {
    position: absolute;
    right: -32%;
    bottom: -30%;

    @media only screen and (max-width: 1024px) {
        right: -32%;
        bottom: -30%;
    }

    @media only screen and (max-width: 957px) {
        right: -32%;
        bottom: -30%;
    }

    @media only screen and (max-width: 576px) {
        right: -65%;
        bottom: -20%;
    }

    @media only screen and (max-width: 380px) {
        right: -75%;
        bottom: -20%;
    }
}

.prdNucleoClipContainer {
    position: absolute;
    right: -40%;
    top: -20%;

    @media only screen and (max-width: 576px) {
        right: -40%;
        top: -20%;
    }

    @media only screen and (max-width: 380px) {
        right: -40%;
        top: -20%;
    }
}

.prdTextBox {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media only screen and (max-width: 576px) {
        padding-bottom: 10%;
    }

    @media only screen and (max-width: 380px) {
        padding-bottom: 10%;
    }

}

.productImg1Style {
    width: 100%;
    margin-top: -50px;
    z-index: 40;

    @media only screen and (max-width: 1024px) {
        margin-top: -50px;
    }

    @media only screen and (max-width: 957px) {
        margin-top: -50px;
    }

    @media only screen and (max-width: 576px) {
        margin-top: -50px;
    }

    @media only screen and (max-width: 380px) {
        margin-top: -50px;
        width: 150%;
    }
}

.prdDisplayBox {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1024px) {
        flex-direction: row;
    }

    @media only screen and (max-width: 957px) {
        flex-direction: row;
    }

    @media only screen and (max-width: 576px) {
        flex-direction: column-reverse;
    }

    @media only screen and (max-width: 380px) {
        flex-direction: column-reverse;
    }
}

.productImg2Clip {
    width: 100%;

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }

    @media only screen and (max-width: 957px) {
        width: 100%;
    }

    @media only screen and (max-width: 576px) {
        width: 60%;
    }

    @media only screen and (max-width: 380px) {
        width: 60%;
    }
}

.joinImg {
    width: 120%;
    z-index: 0;
    left: -10%;
    position: relative;

    @media only screen and (max-width: 1024px) {
        width: 120%;
        left: -10%;
    }

    @media only screen and (max-width: 957px) {
        width: 120%;
        left: -10%;
    }

    @media only screen and (max-width: 576px) {
        width: 140%;
        left: -10%;
    }

    @media only screen and (max-width: 380px) {
        width: 140%;
        left: -10%;
    }
}

.bushesJoinContainer {
    overflow: hidden;
    position: absolute;
    justify-content: center;
    align-items: center;
    align-content: center;
    bottom: -30%;
    
    @media only screen and (max-width: 1024px) {
        bottom: -30%;
    }

    @media only screen and (max-width: 957px) {
        bottom: -30%;
    }

    @media only screen and (max-width: 576px) {
        bottom: -15%;
    }

    @media only screen and (max-width: 380px) {
        bottom: -15%;
    }
}

.cloudJoinImg {
    width: 140%;
    z-index: 100;
    position: relative;

    @media only screen and (max-width: 380px) {
        width: 200%;
    }
}

.flexContainer {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.sticky-container {
    background: rgba(0, 0, 255, 0.2);
    width: 100%;
    margin-top: 100vh;
    height: 100vh;
}

.test {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    height: 200px;
    background: salmon;
}

.target {
    white-space: nowrap;
    top: 100vh;
    width: 10px;
    height: 100vh;
    background: rgba(255, 0, 0, 0.5);
    z-index: 1;
}