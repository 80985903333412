.mbVmContentWrapper {
    min-height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 10;
    transform: translateY(430%);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}

.mbVmProduct {
    width: 100%;
    z-index: 100;
    // left: -20%;
    position: relative;
}

.mbVmTextBox{
    min-height: 350px;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.mbSplashImgContainerVM {
    position: relative;
    overflow-x: hidden;
    background: transparent;
    // background-image: url('../../assets/images/background/splashImg.png');
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    min-height: 900px;
    // margin-top: %;
}

.mbPrdContainerVM {
    width: 100%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    // position: absolute;
    // top: 10%;
}

.mbProductImgStyleVM {
    // margin-top: -70px;
    width: 100%;
    z-index: 40;
}

.mbVmContentContainer{
    display: flex;
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}