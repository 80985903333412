.section5Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    height: 1200px;
}

.ingredientBg {
    position: absolute;
    width: 100%;
    z-index: 5;
}

.ingredientBgImg {
    position: relative;
    overflow-x: hidden;
    background-image: url('../../assets/images/background/ingredientBgImg.webp');
    width: 150%;
    height: 150%;
    background-position: center;
    background-size: cover;
    min-height: 1800px;
    z-index: inherit;


    background-repeat: no-repeat;
    margin-top: 0%;
}

.ingredientBgImg2 {
    width: 130%;
    z-index: 0;
    position: relative;
    margin-top: 8%;
    height: 200%;
    object-fit: none;
}

.ingredientBgImg3 {
    width: 100%;
    vertical-align: middle;
    z-index: 0;
    position: relative;
    height: 100%;
    object-fit: cover;
    min-height: 780px;
}

.ingredientSectionContainer {
    position: absolute;
    z-index: 50;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.ingContentSection {
    height: 100%;
    width: 100%;
    z-index: 999;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    bottom: 5%;
    position: absolute;
    background-color: transparent;

    @media only screen and (max-width: 576px) {
        bottom: 35%;
    }

    @media only screen and (max-width: 380px) {
        bottom: 35%;
    }
}

.ingContentBox {
    backdrop-filter: blur(30px);
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    min-height: 430px;
    width: 50%;
    position: absolute;
    border-radius: 10px;
}

.ingPrdContent {
    display: flex;
    flex-direction: column;
    padding: 10% 20%;
}

.ingredientBgDiv {
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.ingPrdTextBox {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 300px;
    padding: 0px 20px 0px 20px;
    backdrop-filter: blur(20px);
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    width: 450px;
    margin-left: 25%;
    background: rgb(217 160 160 / 20%);

    @media only screen and (max-width: 576px) {
        width: 250px;
        margin-left: unset;
    }

    @media only screen and (max-width: 380px) {
        width: 230px;
        margin-left: unset;
    }
}

.ingCarousel>.slick-prev {
    left: 50px;
    z-index: 80;

    @media only screen and (max-width: 576px) {
        left: 20px;
    }

    @media only screen and (max-width: 380px) {
        left: 20px;
    }
}

.ingCarousel>.slick-next {
    right: 50px;
    z-index: 80;

    @media only screen and (max-width: 576px) {
        right: 20px;
    }

    @media only screen and (max-width: 380px) {
        right: 20px;
    }
}

.ingCarousel>.slick-list>.slick-track>.slick-slide>div {
    transform: scale(0);
    transition: transform .3s cubic-bezier(.4, 0, .2, 1);
}

.ingCarousel>.slick-list>.slick-track>.slick-center>div {
    transform: scale(1);
}

.ingCarousel>.slick-list>.slick-track>.slick-slide {
    height: 700px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    @media only screen and (max-width: 576px) {
        height: 400px;
    }

    @media only screen and (max-width: 380px) {
        height: 400px;
    }
}

.ingOrange {
    width: 100%;

    @media only screen and (max-width: 576px) {
        width: 80%;
    }

    @media only screen and (max-width: 380px) {
        width: 80%;
    }
}

.ingBlueberry {
    width: 150%;

    @media only screen and (max-width: 576px) {
        width: 110%;
    }

    @media only screen and (max-width: 380px) {
        width: 100%;
    }
}

.ingPomeg {
    width: 100%;

    @media only screen and (max-width: 576px) {
        width: 80%;
    }

    @media only screen and (max-width: 380px) {
        width: 70%;
    }
}

.ingAcai {
    width: 80%;

    @media only screen and (max-width: 576px) {
        width: 60%;
    }

    @media only screen and (max-width: 380px) {
        width: 60%;
    }
}

.ingATP {
    width: 180%;

    @media only screen and (max-width: 576px) {
        width: 120%;
    }

    @media only screen and (max-width: 380px) {
        width: 120%;
    }
}

.ingAscorbicAcid {
    width: 110%;

    @media only screen and (max-width: 576px) {
        width: 120%;
    }

    @media only screen and (max-width: 380px) {
        width: 120%;
    }
}

.ingNucleo {
    width: 180%;

    @media only screen and (max-width: 576px) {
        width: 120%;
    }

    @media only screen and (max-width: 380px) {
        width: 100%;
    }
}

.ingCarouselWrapper {
    width: 100%;
    background-color: transparent;
    height: 450px;
    position: absolute;
    top: 15%;

    @media only screen and (max-width: 576px) {
        top: 15%;
    }

    @media only screen and (max-width: 380px) {
        top: 15%;
    }
}

.ingContentWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.ingPara {
    padding: 0px 10px;
}

.contentNucleo {
    z-index: 0;
    width: 450px;
    position: absolute;
    top: -35%;
    left: -65%;

    @media only screen and (max-width: 576px) {
        top: -10%;
        left: -65%;
    }

    @media only screen and (max-width: 380px) {
        top: -5%;
        left: -55%;
    }
}

.contentATP {
    z-index: 0;
    width: 450px;
    position: absolute;
    top: -35%;
    left: -75%;

    @media only screen and (max-width: 576px) {
        top: -10%;
        left: -40%;
    }

    @media only screen and (max-width: 380px) {
        top: -10%;
        left: -40%;
    }
}

.contentAscorbicAcid {
    z-index: 0;
    width: 450px;
    position: absolute;
    top: -20%;
    left: -42.5%;

    @media only screen and (max-width: 576px) {
        top: -10%;
        left: -40%;
    }

    @media only screen and (max-width: 380px) {
        top: -10%;
        left: -40%;
    }
}

.contentOrange {
    z-index: 0;
    width: 450px;
    position: absolute;
    top: -20%;
    left: -45%;

    @media only screen and (max-width: 576px) {
        top: -5%;
        left: -50%;
    }

    @media only screen and (max-width: 380px) {
        top: -5%;
        left: -50%;
    }
}

.contentBlueberry {
    z-index: 0;
    width: 450px;
    position: absolute;
    top: -45%;
    left: -70%;

    @media only screen and (max-width: 576px) {
        top: -15%;
        left: -20%;
    }

    @media only screen and (max-width: 380px) {
        top: -10%;
        left: -20%;
    }
}

.contentPomeg {
    z-index: 0;
    width: 450px;
    position: absolute;
    top: -15%;
    left: -50%;

    @media only screen and (max-width: 576px) {
        top: 5%;
        left: -40%;
    }

    @media only screen and (max-width: 380px) {
        top: -5%;
        left: -40%;
    }
}

.contentAcai {
    z-index: 0;
    width: 450px;
    position: absolute;
    top: -15%;
    left: -40%;

    @media only screen and (max-width: 576px) {
        top: -5%;
        left: -50%;
    }

    @media only screen and (max-width: 380px) {
        top: -5%;
        left: -40%;
    }
}

.testimonialSectionContainer {
    overflow: hidden;
    position: absolute;
    bottom: -180%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 576px) {
        bottom: -120%;
    }

    @media only screen and (max-width: 380px) {
        bottom: -125%;
    }
}

.ingredientSection1{
    position: relative;
    width: 100%;
    min-width: 100vw;
    z-index: 0;
}
